// import React from 'react'

// const useQuestionDelete = () => {
//   return (
//     <div>useQuestionDelete</div>
//   )
// }

// export default useQuestionDelete
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function useQuestionDelete() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const DeletingQuestion = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${BASE_URL}/admin/faq/${id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      Swal.fire({
        icon: "success",
        text: "Ticket deleted successfully",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text:
          error?.response?.data?.error ||
          "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return { DeletingQuestion, setLoading, loading };
}

export default useQuestionDelete;
