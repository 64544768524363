import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const DynamicCounter = ({ end, duration = 2.75 }) => {
  return (
    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
      {({ isVisible }) => (
        <div
          style={{ fontSize: "48px", fontWeight: "bold" }}
          className="text-indigo-600"
        >
          {isVisible ? <CountUp end={end} duration={duration} /> : "0"}
        </div>
      )}
    </VisibilitySensor>
  );
};

const StatisticsSection = () => {
  return (
    <section className="text-center p-10 dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-bold mb-4">Unsere Erfolgsgeschichte</h2>
      <div className="grid gap-8 md:grid-cols-3 grid-cols-1">
        <div>
          <DynamicCounter end={132} />
          <p className="text-lg mt-2">Registrierte Schulen</p>
        </div>
        <div>
          <DynamicCounter end={102439} />
          <p className="text-lg mt-2">Versendete Emails</p>
        </div>
        <div>
          <DynamicCounter end={24712} />
          <p className="text-lg mt-2">E-Mail Listen</p>
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
