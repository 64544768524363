import React, { useEffect, useState } from "react";
import heroImage from "../../assets/images/Dashboard.png";
import StripePricingTable from "./Stripe";
import HowItWorks from "./HowItWorks";
import Testimonials from "./Testimonials";
import Features from "./Features";
import StatisticsSection from "./Statistics";
import Footer from "./Footer";
import FontSizeChanger from "react-font-size-changer";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import DarkModeToggle from "../../components/darkmode-toggle/darkmode-toggle";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuAnimateClass = isOpen ? "scale-y-100" : "scale-y-0";

  return (
    <nav className="bg-white dark:bg-gray-900 shadow-lg">
      <div className="max-w-6xl mx-auto px-4 py-6 flex justify-between items-center">
        <a href="/" className="text-xl font-bold text-indigo-600">
          Klassenbote
        </a>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 focus:outline-none"
          >
            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </div>
        <div
          className={`transform origin-top ${menuAnimateClass} transition-transform
           duration-300 ease-out md:scale-y-100 flex flex-col md:flex-row md:space-x-4 
           space-y-4 md:space-y-0 absolute md:relative top-16 md:top-0 right-0 w-full md:w-auto
           shadow-md md:shadow-none`}
        >
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Home
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Features
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Pricing
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Contact
          </a>
          <a
            href="/register"
            className="block px-4 py-2 text-base bg-indigo-600 text-white rounded-md hover:bg-indigo-700 dark:text-gray-100"
          >
            Sign Up
          </a>
        </div>
      </div>
    </nav>
  );
};

const LandingPage = () => {
  return (
    <div
      className="min-h-screen bg-gray-100"
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      {/* Navigation */}
      <Navbar />

      {/* Hero Section */}
      <div className="bg-white dark:bg-gray-800">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center py-12 md:py-24 px-8">
          <div className="flex flex-col w-full lg:w-1/2 justify-center items-start mr-4">
            <h1 className="text-3xl md:text-5xl text-gray-700 dark:text-gray-100 font-bold">
              Keine nervigen WhatsApp Gruppen mehr!
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-200 mt-4">
              Organisieren Sie ihre Klassen über einfache E-Mail Verteiler.
              Diskret und auf das Wichtigste reduziert.
            </p>
            <button className="bg-indigo-600 text-white mt-6 py-2 px-6 rounded-md hover:bg-indigo-700 transition duration-300">
              Jetzt probieren!
            </button>
          </div>
          <div className="w-full lg:w-1/2 text-center my-8 lg:my-0">
            {/* Add your product image here */}
            <img
              src={heroImage}
              alt="SchoolList Hero"
              className="rounded-xl shadow-lg"
            />
          </div>
        </div>
      </div>

      <Features />
      <StatisticsSection />
      <HowItWorks />
      <Testimonials />

      <div>
        <StripePricingTable
          pricingTableId="prctbl_1OyVd0B7VBBP9Yq15rgvSO34"
          publishableKey="pk_live_51ObMN8B7VBBP9Yq19cxwnBI3cZRmCrBOjwnPobtC44OKBs9invDzdMB1n3gVW8oNBP3CSJ3vKGUUX6ePmnLhwBwK00oTgzbwvt"
        />
      </div>
      <DarkModeToggle />
      <Footer />
    </div>
  );
};

export default LandingPage;
