import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import FloatingInput from "../../../components/input/FloatingInput";
import { ButtonLoader2 } from "../../../components/buttonLoader/ButtonLoader";
import useChangePassword from "../../../hooks/useChangePassword";
import { BiHide, BiShow } from "react-icons/bi";
import Background from "../../../assets/images/background.png";

let Password_REGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;

const formSchema = Yup.object({
  password: Yup.string()
    .required("Passwort ist erforderlich")
    .matches(
      Password_REGX,
      "Das Passwort besteht aus mindestens einem Großbuchstaben, einem Kleinbuchstaben und mindestens einer Ziffer"
    ),
  confirm_password: Yup.string()
    .required("Zur Bestätigung ist ein Passwort erforderlich")
    .oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
});
const initialValues = {
  password: "",
  confirm_password: "",
};
const ChangePassword = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const code = queryParams.get("code");
  const id = queryParams.get("id");
  return (
    <>
      <div
        className=" flex items-center justify-center w-full h-screen px-4"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full flex md:w-[45%] overflow-auto h-full mt-16 mb-5 items-center justify-center">
          <ChangePasswordFrom code={code} id={id} />
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

function ChangePasswordFrom({ code, id }) {
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { loading, changePassword } = useChangePassword();
  const Navigate = useNavigate();

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      if (code && id) {
        await changePassword({
          password: values.password,
          code,
          id,
        });
      }
    },
  });

  const goToSignUp = () => {
    Navigate("/login");
  };
  return (
    <div
      className="flex w-full flex-col justify-start items-center 
       box-border 2xl:px-[120px] lg:px-12 xs:px-4 border-solid  py-5 overflow-auto
     bg-gray-100 rounded-md bg-clip-padding backdrop-filter
       backdrop-blur-sm bg-opacity-30 border border-[#3730a3] shadow-[#3730a3] shadow-md"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-slate-100">
        Aktualisieren Sie Ihr Passwort
      </h2>

      <div className="w-full flex flex-col lg:mt-4 xs:mt-4">
        <div className="w-full relative lg:mt-4 xs:mt-2">
          <FloatingInput
            className="w-full"
            label=" Passwort"
            name="password"
            value={values.password}
            onChange={handleChange}
            type={password ? "text" : "password"}
          />
          <div
            className="h-full absolute top-[13px] right-2 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setPassword(!password)}
          >
            {password ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.password && touched.password && (
          <p className="text-red-700 text-xs mt-1">{errors.password}</p>
        )}
        <div className="w-full relative lg:mt-4 xs:mt-2">
          <FloatingInput
            className="w-[100%]"
            label="Passwort bestätigen"
            name="confirm_password"
            value={values.confirm_password}
            onChange={handleChange}
            type={confirmPassword ? "text" : "password"}
          />

          <div
            className="h-full absolute top-[13px] right-2 cursor-pointer flex items-center justify-center text-center"
            onClick={() => {
              setConfirmPassword(!confirmPassword);
            }}
          >
            {confirmPassword ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.confirm_password && touched.confirm_password && (
          <p className="text-red-700 text-xs mt-1">{errors.confirm_password}</p>
        )}
        <div className="flex items-center justify-between w-full lg:mt-4 xs:mt-4">
          <button
            className="bg-themeColor rounded-[10px] w-[100%] bg-indigo-600  hover:bg-indigo-700
             h-[50px] text-[#fff] font-[600] flex justify-center items-center"
            onClick={handleSubmit}
          >
            {loading ? <ButtonLoader2 /> : "Aktualisieren"}
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-center items-center w-full mt-4">
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
        <div className="box-border border rounded-md w-[12%] text-center text-gray-100">
          oder
        </div>
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
      </div>
      <div className="flex items-center">
        <span className=" text-gray-100 text-[16px] xs:text-[14px] 2xl:py-4 lg:py-4 mdl:py-4 xs:py-4 ">
          Geh zurück zu
        </span>
        <button
          className="font-bold mx-[4px] text-[17px]  text-indigo-600 "
          onClick={goToSignUp}
        >
          Login
        </button>
      </div>
    </div>
  );
}
