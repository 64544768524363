import React from "react";

const Testimonial = ({ quote, author, role }) => (
  <div className="max-w-md py-4 px-8 bg-white dark:bg-gray-700 shadow-lg rounded-lg my-4 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in">
    <div className="mt-2">
      <p className="text-gray-600 dark:text-gray-300 text-lg">{quote}</p>
    </div>
    <div className="mt-4">
      <p className="text-xl font-medium text-indigo-500 right-0">{author}</p>
      <p className="text-xs dark:text-gray-200 text-gray-500">{role}</p>
    </div>
  </div>
);

const Testimonials = () => {
  return (
    <section className="bg-gray-100 dark:bg-gray-800 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center dark:text-gray-200">
          Das sagen unsere Kunden
        </h2>
        <div className="flex flex-wrap justify-center mt-8 gap-16">
          <Testimonial
            quote="KlassenBote ist super leicht zu benutzen und die Kommunikation mit den Eltern ist direkt und effizient."
            author="Max Mustermann"
            role="Lehrer"
          />
          <Testimonial
            quote="Das Einrichten ist wirklich leicht und ich finde es toll dass jede Klasse und Gruppe ihre eigene E-Mail Adresse hat."
            author="John Doe"
            role="Schulleiter"
          />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
