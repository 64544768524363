import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import useGetUserInfo from "./useGetUserInfo";

function useSignin() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();

  // const { getCurrentUser } = useGetUserInfo();
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   const token =
  //     localStorage.getItem("token") || sessionStorage.getItem("token");
  //   if (token) {
  //     window.location.href = "/";
  //   }
  // }, []);

  const Signin = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/users/login/`, payload)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
        Navigate(`/verify-otp/${payload.email}/2fa`);

        // getCurrentUser();
        // window.location.href = "/chat";
        // localStorage.setItem("token", response.data.access);
        // localStorage.setItem("user", response.data.refresh);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { Signin, setLoading, loading };
}
export default useSignin;
