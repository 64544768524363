const Auth = (state, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SET_DARK":
      return {
        ...state,
        dark: action.payload,
      };
    default:
      return state;
  }
};
export default Auth;
