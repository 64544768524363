import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import useGetProfile from "./useGetProfile";
function useProfile() {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const { fetchProfile } = useGetProfile();
  const token = sessionStorage.getItem("token");
  const ProfileManage = async (payload) => {
    setLoading(true);
    await axios
      .put(`${BASE_URL}/users/update-profile/`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response?.data?.message || "Your profile has been updated",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
        fetchProfile();
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.data ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { loading, ProfileManage, setLoading };
}
export default useProfile;
