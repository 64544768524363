import axios from "axios";
import { useCallback, useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import axiosInstance from "../routes/axiosInstance";

const useGetProfile = () => {
  const { setUserInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const token = sessionStorage.getItem("token");
  const [error, setError] = useState(null);
  const fetchProfile = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      sessionStorage.setItem("user_id", response.data.id);
      sessionStorage.setItem("user_name", response.data.first_name);
      sessionStorage.setItem("user_last_name", response.data.last_name);
      sessionStorage.setItem("user_email", response.data.email);
      setUserInfo(response.data);
    } catch (error) {
      if (
        (error.response && error.response.status === 401) ||
        error.response.status === 500
      ) {
        window.location.href = "/";
      }
    }
  }, [BASE_URL, token]);

  return { fetchProfile };
};

export default useGetProfile;
