import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-6xl mx-auto px-4 flex flex-wrap justify-between items-center flex-col md:flex-row">
        <div className="mb-2 md:mb-0">
          <a
            href="/"
            className="text-2xl font-bold text-indigo-500 hover:text-indigo-600"
          >
            Klassenbote
          </a>
        </div>
        <ul className="flex flex-wrap items-center mb-6 md:mb-0">
          {/* <li><a href="#" className="mr-4 hover:underline md:mr-6 ">Über uns</a></li>
          <li><a href="#" className="mr-4 hover:underline md:mr-6">Datenschutzerklärung</a></li>
          <li><a href="#" className="hover:underline">Kontakt</a></li> */}
        </ul>
        <div className="flex items-center">
          <a
            href="https://facebook.com"
            className="text-white mr-6 hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://twitter.com"
            className="text-white mr-6 hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://instagram.com"
            className="text-white hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
      <div className="text-center text-gray-500 text-sm mt-4">
        © {new Date().getFullYear()} YourBrand. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
