import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  userInfo: null,
  dark: false,
};

const GlobalContext = createContext(initialState);
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const setUserInfo = (userInfo) => {
    dispatch({
      type: "SET_USER_INFO",
      payload: userInfo,
    });
  };
  const setDark = (dark) => {
    dispatch({
      type: "SET_DARK",
      payload: dark,
    });
  };
  return (
    <GlobalContext.Provider
      value={{
        userInfo: state.userInfo,
        setUserInfo,
        dark: state.dark,
        setDark,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
