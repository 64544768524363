import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function useEditQuestion() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const EditingQuestion = async (payload, token) => {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/admin/faq/`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      Swal.fire({
        icon: "success",
        text: "Question updated successfully",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text:
          error?.response?.data?.error ||
          "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return { EditingQuestion, setLoading, loading };
}

export default useEditQuestion;
