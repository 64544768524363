import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
function useTicket() {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const userTickets = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/school/ticket/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response?.data?.message || "New ticket created successfully",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setLoading(false);

        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.detail ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, userTickets, setLoading };
}
export default useTicket;
