// import React from "react";

// const SchuleHinzufugen = () => {
//   return (
//     <main>
//       <div className="flex justify-between items-center gap-3">
//         <h2 className="text-xl font-bold">Schule hinzufügen</h2>
//       </div>

//       <div className="card mt-6 mb-4 bg-white md:p-8 p-3 rounded-[8px]">
//         <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
//           <div className="item">
//             <div className="text-center">
//               <p className="text-[14px] font-bold">SCHULLEITUNG</p>
//             </div>
//             <div className="form mt-4 flex flex-col gap-2">
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   VORNAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   NACHNAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   ADRESSE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   STADT
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   BUNDESLAND
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">PLZ</p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   LAND
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   GESCHLECHT
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   E-MAIL
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   MOBILE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="item">
//             <div className="text-center">
//               <p className="text-[14px] font-bold">STV. SCHULLEITUNG</p>
//             </div>
//             <div className="form mt-4 flex flex-col gap-2">
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   VORNAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   NACHNAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   ADRESSE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   STADT
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   BUNDESLAND
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">PLZ</p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   LAND
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   GESCHLECHT
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   E-MAIL
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   MOBILE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="item">
//             <div className="text-center">
//               <p className="text-[14px] font-bold">SCHULDATEN</p>
//             </div>
//             <div className="form mt-4 flex flex-col gap-2">
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   SCHULENAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   SCHULTYP
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   ADRESSE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   KONTAKTDATEN
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   WEBSITE
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   SCHULTRÄGER
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   ANZAHL SCHÜLER
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   ANZAHL KLASSEN
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   KLASSEN VON - BIS
//                 </p>
//                 <input
//                   type="text"
//                   defaultValue={"1 - 6"}
//                   className="input outline-none border-none p-3 text-center bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   KLASSEN TIEFE
//                 </p>
//                 <input
//                   type="text"
//                   defaultValue={"1 - 6"}
//                   className="input outline-none text-center border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   E-MAIL-PRÄFIX
//                 </p>
//                 <input
//                   type="text"
//                   defaultValue={"rb = 7a.rb@klassenbote.de"}
//                   className="input outline-none text-center border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   D. BEAUFTRAGTER
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none text-center border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="item">
//             <div className="text-center">
//               <p className="text-[14px] font-bold">BANKDATEN</p>
//             </div>
//             <div className="form mt-4 flex flex-col gap-2">
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   KONTOINHABER
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   BANKNAME
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   IBAN
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   BIC/SWIFT COD
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   KONTONUMMER
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   BANKLEITZAHL
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//               <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex justify-between items-center">
//                 <p className="text-[12px] py-3 text-left w-[200px] px-3">
//                   SITZ DER BANK
//                 </p>
//                 <input
//                   type="text"
//                   className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px]"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="copyright pt-5 text-center">
//         <p className="text-sm font-bold">© 2023 KLASSENBOTE.DE</p>
//       </div>
//     </main>
//   );
// };

// export default SchuleHinzufugen;
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import useAddSchool from "../../hooks/useAddSchool";
let EMAIL_REGX = /^\S+@\S+\.\S+$/;
let PHONE_REGX = /^(\+49|0)(\d{10}|\d{11})$/;

const formSchema = Yup.object({
  school_name: Yup.string().required("Der Name der Schule ist erforderlich"),
  contact_person: Yup.string().required("Ansprechpartner ist erforderlich"),
  email_address: Yup.string()
    .required("E-Mail-Adresse ist erforderlich")
    .matches(EMAIL_REGX, "Ungültige E-Mail"),
  phone_number: Yup.string()
    .required("Telefonnummer ist erforderlich")
    .matches(PHONE_REGX, "falsches Telefon Nummer Format"),
  address: Yup.string().required("Adresse ist erforderlich"),
  subscription_status: Yup.string().required(
    "Der Abonnementstatus ist erforderlich"
  ),
  classes: Yup.string().required("Anzahl der Klassen ist erforderlich"),
  date: Yup.string().required("Anmeldedatum ist erforderlich"),
});

const initialValues = {
  school_name: "",
  contact_person: "",
  email_address: "",
  phone_number: "",
  address: "",
  subscription_status: "",
  classes: "",
  date: "",
};

const SchuleHinzufugen = () => {
  const { AddScholForm, loading } = useAddSchool();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await AddScholForm({
        school_name: values.school_name,
        contact_person: values.contact_person,
        contact_email: values.email_address,
        phone_number: values.phone_number,
        address: values.address,
        subscription_status: values.subscription_status,
        number_of_classes: values.classes,
        registration_date: values.date,
      });
      resetForm();
    },
  });
  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    formik;

  const handleCancel = () => {
    resetForm();
  };

  return (
    <main>
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">Schule Hinzufügen</h2>
      </div>

      <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px]">
        <div className="form mt-4 flex flex-col gap-3">
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Schulname
            </p>
            <input
              type="text"
              name="school_name"
              value={values.school_name}
              onChange={handleChange}
              placeholder="geben sie den samen der schule ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.school_name && touched.school_name && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.school_name}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Ansprechpartner
            </p>
            <input
              type="text"
              name="contact_person"
              value={values.contact_person}
              onChange={handleChange}
              placeholder="geben sie den kontaktnamen ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.contact_person && touched.contact_person && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.contact_person}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              E-Mail-Adresse
            </p>
            <input
              type="text"
              name="email_address"
              value={values.email_address}
              onChange={handleChange}
              placeholder="e-mail-adresse eingeben..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.email_address && touched.email_address && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.email_address}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Telefonnummer
            </p>
            <input
              type="text"
              name="phone_number"
              value={values.phone_number}
              onChange={handleChange}
              placeholder="telefonnummer eingeben..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.phone_number && touched.phone_number && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.phone_number}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Adresse
            </p>
            <input
              type="text"
              name="address"
              value={values.address}
              onChange={handleChange}
              placeholder="adresse eingeben..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.address && touched.address && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.address}</p>
            </div>
          )}

          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Abonnementstatus
            </p>
            <input
              type="text"
              name="subscription_status"
              value={values.subscription_status}
              onChange={handleChange}
              placeholder="geben sie den abonnementstatus ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.subscription_status && touched.subscription_status && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">
                {errors.subscription_status}
              </p>
            </div>
          )}

          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Anzahl der Klassen
            </p>
            <input
              type="number"
              name="classes"
              value={values.classes}
              onChange={handleChange}
              placeholder="geben sie die anzahl der klassen ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.classes && touched.classes && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.classes}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Anmeldedatum
            </p>
            <input
              type="text"
              name="date"
              value={values.date}
              onChange={handleChange}
              placeholder="geben sie das registrierungsdatum ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.date && touched.date && (
            <div className="w-full flex items-start justify-start">
              <p className="text-red-700 text-xs">{errors.date}</p>
            </div>
          )}

          <div className="flex items-end justify-end w-full gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="mt-4 bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300"
            >
              Stornieren
            </button>
            <button
              onClick={handleSubmit}
              className="mt-4 bg-indigo-600 text-white py-2 px-4 h-[40px] w-[120px] flex items-center justify-center rounded-md hover:bg-indigo-700 transition duration-300"
            >
              {loading ? <ButtonLoader2 /> : "Einreichen"}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SchuleHinzufugen;
