import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiHide, BiShow } from "react-icons/bi";
import FloatingInput from "../../../components/input/FloatingInput";
import Logo from "../../../assets/images/logo.png";
import { ButtonLoader2 } from "../../../components/buttonLoader/ButtonLoader";
import useSignin from "../../../hooks/useSignin";
import Background from "../../../assets/images/background.png";

let EMAIL_REGX = /^\S+@\S+\.\S+$/;
let Password_REGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,32}$/;
const formSchema = Yup.object({
  email: Yup.string()
    .required("E-Mail ist erforderlich")
    .matches(EMAIL_REGX, "Ungültige E-Mail"),
  password: Yup.string()
    .required("Passwort ist erforderlich")
    .matches(
      Password_REGX,
      "Das Passwort besteht aus mindestens einem Großbuchstaben, einem Kleinbuchstaben und mindestens einer Ziffer"
    ),
});
const initialValues = {
  email: "",
  password: "",
};
const Login = () => {
  return (
    <>
      <div
        className=" flex items-center justify-center w-full h-screen px-4"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full flex md:w-[45%] overflow-auto h-full mt-16 mb-5 items-center justify-center">
          <LoginInFrom />
        </div>
      </div>
    </>
  );
};

export default Login;

function LoginInFrom() {
  const { Signin, loading } = useSignin();
  const Navigate = useNavigate();
  const [password, setPassword] = useState(false);

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await Signin({
        email: values.email,
        password: values.password,
      });
    },
  });

  const goToSignUp = () => {
    Navigate("/register");
  };
  return (
    <div
      className="flex w-full flex-col justify-start items-center 
       box-border 2xl:px-[120px] lg:px-12 xs:px-4 border-solid  py-5 overflow-auto
     bg-gray-100 rounded-md bg-clip-padding backdrop-filter
       backdrop-blur-sm bg-opacity-30 border border-[#3730a3] shadow-[#3730a3] shadow-md"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-slate-100">
        Willkommen zurück!
      </h2>
      <p className="text-lg text-center text-slate-100">
        Melden Sie sich an, um die E-Mail-Adresse Ihres Kurses zu verwalten
      </p>

      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col lg:mt-4 xs:mt-4"
      >
        <div className="w-full lg:mt-4 xs:mt-2">
          <FloatingInput
            label="E-Mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            className="w-full"
          />
          {errors.email && touched.email && (
            <p className="text-red-700 text-xs mt-1">{errors.email}</p>
          )}
        </div>

        <div className="w-full relative lg:mt-4 xs:mt-2">
          <FloatingInput
            className="w-full"
            label="Passwort"
            name="password"
            value={values.password}
            onChange={handleChange}
            type={password ? "text" : "password"}
          />
          <div
            className="h-full absolute top-[13px] right-2 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setPassword(!password)}
          >
            {password ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        <div
          className={`w-full flex items-center mt-1 ${
            errors.password ? "justify-between" : "justify-end"
          }`}
        >
          {errors.password && touched.password && (
            <p className="text-red-700 text-xs w-[50%]">{errors.password}</p>
          )}
          <Link
            className={`text-[#fff] text-xs text-right ${
              errors.password ? "w-full" : "w-[50%]"
            }`}
            to="/forgot-password"
          >
            Passwort vergessen ?
          </Link>
        </div>

        <div className="flex items-center justify-between w-full lg:mt-4 xs:mt-4">
          <button
            className="bg-themeColor rounded-[10px] w-[100%] bg-indigo-600  hover:bg-indigo-700
             h-[50px] text-gray-100 font-[600] flex justify-center items-center"
            type="submit"
          >
            {loading ? <ButtonLoader2 /> : "Login"}
          </button>
        </div>
      </form>

      <div className="flex flex-row justify-center items-center w-full mt-4">
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
        <div className="box-border border rounded-md w-[12%] text-center text-gray-100">
          oder
        </div>
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
      </div>
      <div className="flex items-center">
        <span className=" text-gray-100 text-[16px] xs:text-[14px] 2xl:py-4 lg:py-4 mdl:py-4 xs:py-4 ">
          Sie haben kein Konto ?
        </span>
        <button
          className="mx-[4px] text-[17px] font-bold text-indigo-600"
          onClick={goToSignUp}
        >
          Registrieren
        </button>
      </div>
    </div>
  );
}
