import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import axiosInstance from "../routes/axiosInstance";
// import useGetUserInfo from "./useGetUserInfo";

function useAddSchool() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const { getCurrentUser } = useGetUserInfo();
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");
  const AddScholForm = async (payload) => {
    setLoading(true);
    await axiosInstance
      .post(`${BASE_URL}/school/add-school/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text:
            response?.data?.message || "Message is broadcasted successfully",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { AddScholForm, setLoading, loading };
}
export default useAddSchool;
// this is branch
