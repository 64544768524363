import React from "react";

function FloatingInput(props) {
  const { label, onChange, value, type, name } = props;
  return (
    <>
      <div class="relative">
        <label
          for={name}
          className="block text-sm font-bold mb-2 text-slate-200"
        >
          {label}
        </label>
        <input
          name={name}
          type={type}
          id={name}
          className="rounded w-full py-3 px-4 leading-tight bg-slate-100 text-slate-800 border-2 border-transparent hover:border-indigo-600 focus:border-indigo-600 hover:bg-white outline-none transition-all duration-300 ease-out"
          placeholder=" "
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
}

export default FloatingInput;
