import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const TicketModel = ({ setShow, rowData }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white dark:bg-gray-800 dark:text-white relative">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-700 rounded-full cursor-pointer"
          onClick={() => setShow(false)}
        />
        <h2 className="text-xl font-bold py-4">Ticket Detail</h2>
        <div className="w-full border border-solid border-black dark:border-white flex items-start justify-start">
          <div className="w-1/2 flex flex-col">
            <p className="border border-solid border-black dark:border-white  px-5 py-3 font-semibold">
              Tickect ID
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3 font-semibold">
              Subject
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3 font-semibold">
              Status
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3 font-semibold">
              Priority
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3 font-semibold">
              Date Submitted
            </p>
          </div>
          <div className="w-1/2 flex flex-col">
            <p className="border border-solid border-black dark:border-white px-5 py-3">
              {rowData.id ? rowData.id : ""}
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3">
              {rowData.title ? rowData.title : ""}
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3">
              {rowData.status ? rowData.status : ""}
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3">
              {rowData.priority ? rowData.priority : ""}
            </p>
            <p className="border border-solid border-black dark:border-white px-5 py-3">
              {rowData.created_at ? formatDate(rowData.created_at) : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketModel;
