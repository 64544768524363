import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUsers,
  faShieldAlt,
  faWandMagic,
  faScrewdriverWrench,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

const Feature = ({ title, description, icon }) => {
  return (
    <div
      className="flex flex-col items-center p-4 text-center
       hover:scale-110 hover:shadow-xl rounded-md hover:bg-highlight
        hover:text-white group transition-all duration-300 ease-in dark:bg-gray-800"
    >
      <div className="flex-shrink-0">
        <FontAwesomeIcon
          icon={icon}
          size="3x"
          className="text-indigo-500 group-hover:text-white"
        />
      </div>
      <div className="mt-3">
        <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-50 group-hover:text-white">
          {title}
        </h4>
        <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-100 group-hover:text-slate-200">
          {description}
        </p>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <div className="py-12 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white  sm:text-3xl sm:leading-9">
            Das bringt Klassenbote mit sich
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Kuchenbasar, Elternsprechtag oder Computer AG: einfach organisiert.
          </p>
        </div>

        <div className="mt-10 mx-10">
          <ul className="md:grid md:grid-cols-3 md:gap-x-2 md:gap-y-10">
            <Feature
              icon={faEnvelope}
              title="Unbegrenzte E-Mail Listen"
              description="Erstellen von Listen nicht nur für Klassen, sondern auch AGs und weitere Gruppen."
            />
            <Feature
              icon={faUsers}
              title="Klassen Management"
              description="Einfache Kommunikation zwischen Eltern und Klassenlehrer"
            />
            <Feature
              icon={faShieldAlt}
              title="DSVGO"
              description="Alle Inhalte bleiben privat und richten sich nach der DSGVO"
            />
            <Feature
              icon={faWandMagic}
              title="Automatische Namensgebung"
              description="E-Mail Listen für Klassen werden automatisch zu den Sommerferien umbenannt"
            />
            <Feature
              icon={faScrewdriverWrench}
              title="Administration"
              description="Einfache Übersicht und verwaltung aller E-Mail Listen"
            />
            <Feature
              icon={faHeadset}
              title="Support"
              description="Wenn etwas nicht klappt wie geplant, helfen wir schnell weiter"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Features;
