import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import FloatingInput from "../../../components/input/FloatingInput";
import Logo from "../../../assets/images/logo.png";
import { ButtonLoader2 } from "../../../components/buttonLoader/ButtonLoader";
import useForgotPassword from "../../../hooks/useForgotPassword";
import Background from "../../../assets/images/background.png";

let EMAIL_REGX = /^\S+@\S+\.\S+$/;
const formSchema = Yup.object({
  email: Yup.string()
    .required("E-Mail ist erforderlich")
    .matches(EMAIL_REGX, "Ungültige E-Mail"),
});
const initialValues = {
  email: "",
};
const ForgotPassword = () => {
  return (
    <>
      <div
        className=" flex items-center justify-center w-full h-screen px-4"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full flex md:w-[45%] overflow-auto h-full mt-16 mb-5 items-center justify-center">
          <ForgotPasswordFrom />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

function ForgotPasswordFrom() {
  const { loading, sendEmail } = useForgotPassword();
  const Navigate = useNavigate();

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await sendEmail({
        email: values.email,
      });
    },
  });

  const goToSignUp = () => {
    Navigate("/login");
  };
  return (
    <div
      className="flex w-full flex-col justify-start items-center 
       box-border 2xl:px-[20px] lg:px-12 xs:px-4 border-solid  py-5 overflow-auto
     bg-gray-100 rounded-md bg-clip-padding backdrop-filter
       backdrop-blur-sm bg-opacity-30 border border-[#3730a3] shadow-[#3730a3] shadow-md"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-slate-100">
        Passwort zurücksetzen
      </h2>
      <div className="p-4 bg-indigo-100 border border-indigo-600 text-indigo-600 rounded">
        <p className="text-sm">
          Bitte geben Sie die E-Mail-Adresse der Person ein, die für die
          Klassenliste verantwortlich ist. Wenn die E-Mail-Adresse korrekt ist,
          wird ein neuer Passwort-Link an diese Adresse gesendet.
        </p>
      </div>
      <div className="w-full flex flex-col lg:mt-4 xs:mt-4">
        <div className="w-full lg:mt-4 xs:mt-2">
          <FloatingInput
            label="E-Mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            className="w-full"
          />
          {errors.email && touched.email && (
            <p className="text-red-700 text-xs mt-3 font-semibold">
              {errors.email}
            </p>
          )}
        </div>
        <div className="flex items-center justify-between w-full lg:mt-4 xs:mt-4">
          <button
            className="bg-themeColor rounded-[10px] w-[100%] bg-indigo-600  hover:bg-indigo-700
             h-[50px] text-gray-100 font-[600] flex justify-center items-center"
            onClick={handleSubmit}
          >
            {loading ? <ButtonLoader2 /> : "Nächste"}
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-center items-center w-full mt-4">
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
        <div className="box-border border rounded-md w-[12%] text-center text-gray-100 ">
          oder
        </div>
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
      </div>
      <div className="flex items-center">
        <span className=" text-gray-100 text-[16px] xs:text-[14px] 2xl:py-4 lg:py-4 mdl:py-4 xs:py-4 ">
          GZurück zum
        </span>
        <button
          className="font-bold mx-[4px] text-[17px] text-indigo-600"
          onClick={goToSignUp}
        >
          Login
        </button>
      </div>
    </div>
  );
}
