import React from "react";
import "../../assets/css/Loader.css";

// Colored Loader
export function ButtonLoader1() {
  return (
    <>
      <span class="loader-1"></span>
    </>
  );
}

// White Loader
export function ButtonLoader2() {
  return (
    <>
      <span class="loader-2"></span>
    </>
  );
}
