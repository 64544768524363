import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import useGetProfile from "./useGetProfile";
function useUpdatePassword() {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { fetchProfile } = useGetProfile();
  const token = sessionStorage.getItem("token");
  const PasswordManage = async (payload) => {
    setPasswordLoading(true);
    await axios
      .put(`${BASE_URL}/users/password/change/`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPasswordLoading(false);
        Swal.fire({
          icon: "success",
          text: response?.data?.message || "Your password has been updated",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
        fetchProfile();
      })
      .catch((error) => {
        setPasswordLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.data ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { passwordLoading, PasswordManage };
}

export default useUpdatePassword;
