import React, { useState } from "react";
import { DatePicker, Select } from "antd";
import StatCard from "../../components/statCard";
import LineChart1 from "../../components/line-chart";
import { GrDocumentDownload } from "react-icons/gr";
import TinyBarChart from "../../components/tinyBarChart";
import TinyLineChart from "../../components/tiny-line-chart";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProfileCard from "../../components/profile-card";
import SupportBarChart from "../../components/barChart";
import SchoolBarChart from "../../components/schoolBarChart";
import ClassBarChart from "../../components/classBarChart";

const Dashboard = () => {
  const [isActive, setIsActive] = useState(1);
  const { RangePicker } = DatePicker;
  const cardData = [
    {
      id: 1,
      name: "aktive Schulen",
      value: "12",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "aktive E-Mail Slots",
      value: "10.2 K",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 3,
      name: "Einnahme",
      value: "13,7 K €",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 4,
      name: "Offene Tickets",
      value: "7",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 5,
      name: "Nicht reg. E-Mails",
      value: "12",
      perc: 2.3,
      stat: "up",
      comment: "seit letzten Jahr",
    },
    {
      id: 6,
      name: "Avg. Order Value",
      value: "$306.20",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
  ];
  const supportCardData = [
    {
      id: 1,
      name: "Unterstützung  Anfragen",
      value: "12",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "gelöste Probleme",
      value: "10",
      perc: 1.8,
      stat: "up",
      comment: "seit letzten Jahr",
    },
  ];
  const schoolCardData = [
    {
      id: 1,
      name: "Anzahl der angemeldeten Klassen",
      value: "50",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "Anzahl der aktiven E-Mail-Adressen",
      value: "200",
      perc: 1.8,
      stat: "up",
      comment: "seit letzten Jahr",
    },
  ];
  const [user, setUser] = useState("admin");
  return (
    <main>
      {user === "admin" && (
        <AdminDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={cardData}
        />
      )}
      {user === "support" && (
        <SupportDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={supportCardData}
        />
      )}
      {user === "school" && (
        <SchoolDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={schoolCardData}
        />
      )}
      {user === "class" && <ClassDashboard />}
    </main>
  );
};

export default Dashboard;
const AdminDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3 dark:text-white">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white dark:bg-gray-700 border-blue-500"
                  : "bg-white dark:bg-gray-500"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white dark:bg-gray-700 border-blue-500"
                  : "bg-white dark:bg-gray-500"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>

      <div className="mt-8 mb-4 grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
      </div>

      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Gesendete E-Mails</p>
          <Select
            defaultValue="letzten 12 Monate"
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            // onChange={handleChange}
            options={[
              { value: "letzten 12 Monate", label: "letzten 12 Monate" },
              { value: "letzten 15 Monate", label: "letzten 15 Monate" },
            ]}
          />
        </div>
        <LineChart1 />
      </div>

      <div className="grid lg:grid-cols-3 grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
              <p className="text-lg font-bold">Sales Report</p>
              <button className="btn border border-gray-300 dark:text-white  text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <GrDocumentDownload />
                <span>Export</span>
              </button>
            </div>

            <div className="mt-14">
              <TinyBarChart />
            </div>
          </div>
        </div>

        <div className="col-span-1 h-full">
          <div className="card flex flex-col justify-center h-full w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">MAILGUN LIMIT</p>
            </div>
            <h3 className="mt-6 text-2xl font-bold">15.149 / 50.000</h3>

            <div className="flex mt-3 justify-start items-center gap-2">
              <span className={`font-bold text-sm text-green-500 `}>1.8%</span>
              <span className={`font-bold text-sm mb-1 text-green-500`}>↑</span>
              <span
                className={`font-normal text-sm mb-1 text-gray-400 dark:text-white`}
              >
                in diesen Monat
              </span>
            </div>

            <div className="line mt-6 h-[1px] bg-black dark:bg-gray-100"></div>
            <TinyLineChart />
            <div className="line mt-6 h-[1px] bg-black dark:bg-gray-100"></div>

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <h2 className="text-xl font-bold">Aktivität Statistiken</h2>

        <div className="mt-14 ">
          <SupportBarChart />
        </div>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="h-[200px] overflow-y-auto">
              <table className="table w-[180%] md:w-full">
                <thead>
                  <th className="border p-2 text-sm dark:bg-gray-600 ">
                    SCHULE
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600  ">
                    THEMA
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600 ">
                    KATEGORIE
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600">
                    TICKET-NR.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0124</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Organic search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">KRITISCH</td>
                    <td className="border p-2 text-sm text-center">#0125</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">Email</td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">WICHTIG</td>
                    <td className="border p-2 text-sm text-center">#0126</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pagination mt-5 flex justify-center items-center gap-2">
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronLeft />
              </button>
              <button className="btn bg-blue-500 text-white px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                1
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                2
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                3
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                4
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                5
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
        <div className="col-span-1 h-full">
          <div className="card h-full w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">Neue Schulen</p>
            </div>

            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};
const SupportDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  const logsData = [
    {
      id: "001",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "002",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "003",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Teacher",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "004",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "005",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Teacher",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLogs, setFilteredLogs] = useState(logsData);
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredLogs(
      logsData.filter(
        (log) =>
          log.userRole.toLowerCase().includes(term) ||
          log.details.toLowerCase().includes(term)
      )
    );
  };

  return (
    <main>
      <div className="flex md:flex-row flex-col dark:text-white justify-between items-center gap-3">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>

      <div className="mt-8 mb-4 grid lg:grid-cols-2 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
      </div>

      <div className="grid grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded ">
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center my-5">
              <p className="text-[12px] py-3 text-left w-[200px] px-3">
                Suchen
              </p>
              <input
                type="text"
                name="search"
                placeholder="hier suchen..."
                value={searchTerm}
                onChange={handleSearch}
                className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px] dark:bg-gray-500 dark:placeholder:text-gray-300"
              />
            </div>
            <div className="h-[300px] overflow-y-auto">
              <table className="table w-[180%] md:w-full">
                <thead>
                  <th className="border p-2 text-sm">Protokoll-ID</th>
                  <th className="border p-2 text-sm">Zeitstempel</th>
                  <th className="border p-2 text-sm">Aktivitätstyp</th>
                  <th className="border p-2 text-sm">Benutzer-ID</th>
                  <th className="border p-2 text-sm">Benutzerrolle</th>
                  <th className="border p-2 text-sm">Einzelheiten</th>
                  <th className="border p-2 text-sm">Status</th>
                  <th className="border p-2 text-sm">IP-Adresse</th>
                </thead>
                <tbody>
                  {filteredLogs.length > 0 ? (
                    filteredLogs.map((log) => (
                      <tr key={log.id}>
                        <td className="border p-2 text-sm text-center">
                          {log.id}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.timestamp}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.activityType}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.userId}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.userRole}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.details}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.status}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.ipAddress}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        className="border p-2 text-sm text-center text-red-600"
                      >
                        Keine Ergebnisse gefunden
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="pagination mt-5 flex justify-center items-center gap-2">
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronLeft />
              </button>
              <button className="btn bg-blue-500 text-white px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                1
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                2
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                3
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                4
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                5
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="col-span-1 h-full">
          <div className="card h-full w-full bg-white px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">Neue Schulen</p>
            </div>

            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div> */}
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Aktivität Statistiken</p>
          <button className="btn border border-gray-300 dark:border-white dark:text-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <SupportBarChart />
        </div>
      </div>
    </main>
  );
};
const SchoolDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  return (
    <main>
      <div className="flex md:flex-row flex-col dark:text-white justify-between items-center gap-3">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>

      <div className="mt-8 mb-4 grid lg:grid-cols-3 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
        <div className="shadow rounded bg-white dark:bg-gray-700 p-3">
          <p className="text-gray-400 dark:text-gray-200  text-xs mb-3">
            Abonnement status
          </p>
          <h3 className="text-xl font-semibold text-black dark:text-white">
            WAHR
          </h3>
        </div>
      </div>

      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Aktivitäts daten</p>
          <button className="btn border border-gray-300 dark:border-white dark:text-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <SchoolBarChart />
        </div>
      </div>
    </main>
  );
};
const ClassDashboard = () => {
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3 dark:text-white">
        <h2 className="text-xl font-bold">Dashboard</h2>
      </div>

      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Daten zur Klassenaktivität</p>
          <button className="btn border border-gray-300 dark:text-white dark:border-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <ClassBarChart />
        </div>
      </div>
    </main>
  );
};
