import React, { useEffect } from "react";

const StripePricingTable = ({ pricingTableId, publishableKey }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="lg:text-center bg-white dark:bg-gray-900 dark:text-white ">
      <div className="container mx-auto px-4 py-12 dark:bg-white">
        <h2 className="text-3xl font-bold text-center dark:text-gray-900">
          Preise
        </h2>
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
        ></stripe-pricing-table>
      </div>
    </div>
  );
};

export default StripePricingTable;
