import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function useChangePassword() {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const changePassword = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/users/password/reset/`, payload)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
        Navigate(`/login`);
      })
      .catch((error) => {
        setLoading(false);

        Swal.fire({
          icon: "error",
          text:
            error.response.data.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, changePassword };
}

export default useChangePassword;
