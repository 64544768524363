import React, { PureComponent, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const data = [
  {
    name: "Jan",
    Emails: 500,
  },
  {
    name: "Feb",
    Emails: 400,
  },
  {
    name: "Mar",
    Emails: 200,
  },
  {
    name: "Apr",
    Emails: 400,
  },
  {
    name: "May",
    Emails: 500,
  },
  {
    name: "Jun",
    Emails: 450,
  },
  {
    name: "Jul",
    Emails: 430,
  },
  {
    name: "Aug",
    Emails: 500,
  },
  {
    name: "Sep",
    Emails: 440,
  },
  {
    name: "Oct",
    Emails: 450,
  },
  {
    name: "Nov",
    Emails: 410,
  },
  {
    name: "Dec",
    Emails: 450,
  },
];

const LineChart1 = () => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer
      width="100%"
      className={"chart-container text-gray-100"}
    >
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          tickLine={false}
          color="red"
          stroke={dark ? "#fff" : "#000"}
        />
        <YAxis
          tickLine={false}
          axisLine={true}
          stroke={dark ? "#fff" : "#000"}
        />
        <Tooltip />
        <Legend wrapperStyle={{ top: 0 }} />
        <Line
          type="monotone"
          dataKey="Emails"
          stroke={dark ? "#fff" : "#4040ce"}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default LineChart1;
