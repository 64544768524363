import React from "react";

const Step = ({ step, title, description }) => (
  <div
    className="flex flex-col items-center text-center
   p-4 w-80 md:w-1/4 hover:scale-110 hover:shadow-xl rounded-md hover:bg-highlight
    hover:text-white group transition-transform duration-300 ease-in dark:bg-gray-800 dark:text-white"
  >
    <div className="text-6xl font-bold text-indigo-600 group-hover:text-white">
      {step}
    </div>
    <h3 className="mt-2 text-xl font-semibold dark:text-white">{title}</h3>
    <p className="mt-2 text-gray-600 dark:text-slate-200 group-hover:text-slate-200">
      {description}
    </p>
  </div>
);

const HowItWorks = () => {
  return (
    <section className="py-12 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center dark:text-white">
          So funktioniert's
        </h2>
        <div className="mt-8 flex flex-wrap justify-center gap-8">
          <Step
            step="1"
            title="Anmelden"
            description="Nur wenige klicks zum Erstellen eines Accounts"
          />
          <Step
            step="2"
            title="Schule erstellen"
            description="Die Schule muss bei KlassenBote registriert werden, um E-Mails versenden zu können"
          />
          <Step
            step="3"
            title="Klassenliste erstellen"
            description="E-Mail für die 7a des Goethe Gymnasiums wäre '7a.schule@klassenbote.de'"
          />
          <Step
            step="4"
            title="Mitglieder hinzufügen"
            description="Eine Liste kann unbegrenzt viele Mitglieder haben"
          />
          <Step
            step="5"
            title="E-Mails versenden"
            description="E-Mails an '7a.schule@klassenbote.de' gehen an alle Mitglieder. Antworten nur an den Absender"
          />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
