import React from "react";
import Icon from "../../../assets/images/notFound.svg";
const NotFound = () => {
  return (
    <div className="w-full h-full text-3xl flex dark:text-white flex-col items-center justify-center gap-4">
      <img
        src={Icon}
        className=" w-1/2 dark:bg-gray-600 rounded-full mix-blend-multiply dark:mix-blend-normal"
      />
      <p>Nicht-gefunden</p>
    </div>
  );
};

export default NotFound;
