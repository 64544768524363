import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useVerifyOtp from "../../../hooks/useVerifyOtp";
import { ButtonLoader2 } from "../../../components/buttonLoader/ButtonLoader";
import Background from "../../../assets/images/background.png";
import useResend from "../../../hooks/useResend";

const VerifyOtp = () => {
  const { email } = useParams();
  const { loading, verifyOTP } = useVerifyOtp();
  const [checkOTP, setCheckOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(300);
  const [formattedTime, setFormattedTime] = useState("05:00");
  const [isResendVisible, setIsResendVisible] = useState(false);
  const location = useLocation();
  const { Resend } = useResend();

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        setFormattedTime(
          `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`
        );
      } else {
        clearInterval(timer);
        setIsResendVisible(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  const handleVerify = () => {
    if (otp.length === 4) {
      if (location.pathname.includes("2fa")) {
        verifyOTP({ otp_code: otp, email });
      } else {
      }
    } else {
      setCheckOTP(true);
    }
  };

  const handleResend = () => {
    Resend({ email: email });
    setSeconds(300);
    setFormattedTime("05:00");
    setIsResendVisible(false);
  };

  return (
    <>
      <div
        className="flex justify-center items-center h-screen px-4"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="2xl:w-[40%] lg:w-[40%] md:w-[50%] xs:w-full
         lg:h-[470px] xs:h-auto flex flex-col justify-center items-center
           box-border 2xl:px-[20px] lg:px-12 xs:px-4 border-solid  py-5 overflow-auto
     bg-gray-100 rounded-md bg-clip-padding backdrop-filter
       backdrop-blur-sm bg-opacity-30 border border-[#3730a3] shadow-[#3730a3] shadow-md"
        >
          <h2 className="text-3xl font-bold mb-6 text-center text-slate-100">
            Geben Sie den 4-stelligen Code ein
          </h2>

          <span className="text-1xl font-bold mb-6 text-center text-slate-100">
            Geben Sie den 4-stelligen Code ein, den Sie per E-Mail erhalten
            haben.
          </span>
          <OtpInput length={4} setOtp={setOtp} setCheckOTP={setCheckOTP} />
          {checkOTP && (
            <p className="text-[15px] font-medium text-red-600 pt-3">
              * Geben Sie das OTP ein
            </p>
          )}

          <button
            onClick={handleVerify}
            disabled={seconds <= 0}
            className="text-white bg-indigo-600 hover:bg-indigo-700 cursor-pointer bg-themeColor lg:w-[80%] w-full h-[50px] text-center text-[20px] flex justify-center items-center rounded-[5px] mt-8"
          >
            {loading ? <ButtonLoader2 /> : "Verifizieren"}
          </button>
          <div className="py-4 gap-1 flex">
            <span className="text-gray-100 text-[14px] font-medium">
              Wenn Sie keinen Code erhalten haben?
            </span>
            {isResendVisible ? (
              <button
                className="text-gray-100 hover:text-indigo-400 text-[14px] font-bold underline"
                onClick={handleResend}
              >
                Erneut senden
              </button>
            ) : (
              <span className="text-gray-100 hover:text-indigo-400 text-[14px] font-bold underline">
                {formattedTime}
              </span>
            )}
          </div>

          <div className="flex justify-center items-center gap-1 lg:mt-8 xs:mt-2">
            <span className="text-gray-100 text-sm font-medium">Zurück zu</span>
            <Link
              to="/login"
              className="font-bold mx-[4px] text-[17px] text-indigo-600"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;

function OtpInput({ length, setOtp, setCheckOTP }) {
  const inputs = Array.from({ length }, (_, index) => React.createRef());

  const handleInputChange = (event, index) => {
    const inputElement = event.target;
    const nextInput = inputs[index + 1]?.current;

    if (inputElement.value !== "" && nextInput) {
      nextInput.focus();
    }

    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ""
    );
    setOtp(otpValue);
    setCheckOTP(false);
  };

  const handleInputKeyDown = (event, index) => {
    const inputElement = event.target;
    const previousInput = inputs[index - 1]?.current;

    if (
      event.key === "Backspace" &&
      inputElement.value === "" &&
      previousInput
    ) {
      previousInput.focus();
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    setCheckOTP(false);
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const pastedCharacters = pastedData.split("");
    for (let i = 0; i < length; i++) {
      if (i < pastedCharacters.length) {
        inputs[i].current.value = pastedCharacters[i];
      } else {
        inputs[i].current.value = "";
      }
    }
    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ""
    );
    setOtp(otpValue);
  };

  return (
    <div className="flex items-center justify-center gap-3 lg:mt-4 xs:mt-2">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          placeholder="-"
          maxLength="1"
          pattern="[0-9]"
          inputMode="numeric"
          ref={inputs[index]}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onKeyDown={(e) => handleInputKeyDown(e, index)}
          className="md:w-[60px] md:h-[60px] xs:w-[44px] xs:h-[44px] 2xl:w-[80px] 2xl:h-[80px] bg-[#fffff] text-center outline-none rounded-xl text-lg border border-[#DFDFDF] text-[#95969D]"
        />
      ))}
    </div>
  );
}
