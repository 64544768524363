import ReactDOM from "react-dom/client";
import "./assets/css/config.css";
import "./assets/css/global.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { GlobalProvider } from "./context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalProvider>
    <RouterProvider router={router} />
  </GlobalProvider>
);
