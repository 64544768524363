import { DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { IoIosClose } from "react-icons/io";
import useGetUserActivity from "../../hooks/useGetUserActivity";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const getWeekRange = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });
  return [start, end];
};
const getMonthRange = (date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return [start, end];
};
const UserActivity = () => {
  const [isActive, setIsActive] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState([]);
  const { UserActivity, loading, tableData } = useGetUserActivity();
  const rowsPerPage = 10;
  const paginationRef = useRef(null);

  const { RangePicker } = DatePicker;

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };

  const filteredQuestions = tableData.filter((item) => {
    const itemDate = dayjs(item.timestamp);
    const today = dayjs();
    let isInDateRange = false;

    switch (isActive) {
      case 1: // Tag
        isInDateRange = itemDate.isSame(today, "day");
        break;
      case 2: // Woche
        const [weekStart, weekEnd] = getWeekRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(weekStart)) &&
          itemDate.isSameOrBefore(dayjs(weekEnd));
        break;
      case 3: // Monat
        const [monthStart, monthEnd] = getMonthRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(monthStart)) &&
          itemDate.isSameOrBefore(dayjs(monthEnd));
        break;
      default:
        isInDateRange = true;
    }

    return (
      item?.action?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isInDateRange
    );
  });

  const totalPages = Math.ceil(filteredQuestions.length / rowsPerPage);
  const currentData = filteredQuestions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // const handlePrevPage = () => {
  //   setCurrentPage((prev) => Math.max(prev - 1, 1));
  // };

  // const handleNextPage = () => {
  //   setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  // };
  // Scroll the active button into view
  const scrollToActiveButton = (index) => {
    if (paginationRef.current) {
      const activeButton = paginationRef.current.children[index];
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  useEffect(() => {
    scrollToActiveButton(currentPage - 1);
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    UserActivity(isActive, dateRange);
  }, [isActive, dateRange]);
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Benutzer Aktivität
        </h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500 hover:text-white border  border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700 "
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white border-blue-500  dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Monat
            </button>
            {isActive !== 0 && (
              <IoIosClose
                className="text-[18px] text-gray-800 dark:text-white ml-1 cursor-pointer"
                onClick={() => setIsActive(0)}
              />
            )}
          </div>
          <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
        </div>
      </div>
      <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center mt-5 mb-8">
        <p className="text-[12px] py-3 text-left lg:w-[200px] w-[100px] px-3 dark:text-white">
          Suchen
        </p>
        <input
          type="text"
          className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 w-full rounded-[6px] text-[12px] lg:text-[16px]"
          placeholder="Suchen Sie nach einem bestimmten Ergebnis..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className=" bg-white dark:bg-gray-700 pb-6 mb-4 rounded-[8px]">
        <div className="overflow-x-auto">
          <table className="table w-[400%] md:w-full mb-[200px]">
            <thead>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Benutzer
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Aktion
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Zeitstempel
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Details
              </th>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center text-gray-500 py-4">
                    <ButtonLoader2 />
                  </td>
                </tr>
              ) : currentData.length > 0 ? (
                currentData.map((item) => (
                  <tr key={item.id}>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      <Link to="">
                        {item.user_name ? item.user_name : "--"}
                      </Link>
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left ">
                      <Link to="">{item.action}</Link>
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {dayjs(item.timestamp).format("YYYY-MM-DD")} -{" "}
                      {dayjs(item.timestamp).format("HH:mm:ss")}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.details}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="text-center text-gray-500 dark:text-white py-4"
                  >
                    Keine Datensätze gefunden
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {currentData.length > 0 && (
          <div className="pagination mt-5 flex justify-center items-start gap-2 w-full overflow-hidden">
            <button
              className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <div
              className="w-[50%] flex overflow-auto justify-start items-start gap-2"
              ref={paginationRef}
            >
              {[...Array(totalPages).keys()].map((page) => (
                <button
                  key={page}
                  className={`btn ${
                    currentPage === page + 1
                      ? "bg-blue-500 text-white"
                      : "text-gray-400"
                  } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
                  onClick={() => setCurrentPage(page + 1)}
                >
                  {page + 1}
                </button>
              ))}
            </div>
            <button
              className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default UserActivity;
