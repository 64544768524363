import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { ButtonLoader1 } from "../buttonLoader/ButtonLoader";

const AllSchoolTable = ({
  tableHeader,
  currentData,
  handlePrevPage,
  handleNextPage,
  currentPage,
  setCurrentPage,
  totalPages,
  loading,
}) => {
  return (
    <div className=" bg-white dark:bg-gray-700 pb-6 mb-4 rounded-[8px]">
      <div className="overflow-x-auto">
        <table className="table w-[400%] md:w-full mb-[200px] relative">
          <thead>
            {tableHeader.map((item, index) => (
              <th
                key={index}
                className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left capitalize"
              >
                {item}
              </th>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  colSpan="8"
                  className="text-center text-gray-500 py-4 mt-20"
                >
                  <ButtonLoader1 />
                </td>
              </tr>
            ) : currentData.length > 0 ? (
              currentData.map((item) => (
                <tr key={item.id}>
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    <Link to="">{item.school_name}</Link>
                  </td>
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    <Link to="">{item.contact_person}</Link>
                  </td>
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    {item.contact_email}
                  </td>
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    {item.phone_number}
                  </td>
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    {item.subscription_status}
                  </td>{" "}
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    {item.number_of_classes}
                  </td>{" "}
                  <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                    <div className="flex text-xs justify-start items-center gap-2">
                      <span>{item.registration_date}</span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="8"
                  className="text-center text-gray-500 py-4 mt-20"
                >
                  <span className="flex items-center justify-center w-full">
                    <MdOutlineDoNotDisturbAlt className="text-red-600 mr-2" />{" "}
                    Keine Datensätze gefunden
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination mt-5 flex justify-center items-center gap-2">
        {totalPages !== 0 && (
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
        )}

        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page}
            className={`btn ${
              currentPage === page + 1
                ? "bg-blue-500 text-white dark:bg-gray-500"
                : "text-gray-400"
            } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </button>
        ))}
        {totalPages !== 0 && (
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default AllSchoolTable;
