import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import { IoIosAdd } from "react-icons/io";
import useSchoolStructure from "../../hooks/useSchoolStructure";

const formSchema = Yup.object({
  grades: Yup.array()
    .of(
      Yup.object({
        grade: Yup.string().required("Note ist erforderlich"),
        classes: Yup.array().of(
          Yup.string().required("Klasse ist erforderlich")
        ),
      })
    )
    .required("Noten sind erforderlich"),
  emailSuffix: Yup.string().required("E-Mail-Suffix ist erforderlich"),
});

const initialValues = {
  grades: [{ grade: "", classes: [""] }],
  emailSuffix: "",
};

const SchoolStructure = () => {
  const [show, setShow] = useState(false);
  const { SchoolStructureSubmit, loading } = useSchoolStructure();

  const handleMenu = () => {
    setShow(!show);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await SchoolStructureSubmit({
        grades: values.grades,
        emailSuffix: values.emailSuffix,
      });
      resetForm();
    },
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = formik;

  const handleCancel = () => {
    resetForm();
  };

  const addGrade = () => {
    setFieldValue("grades", [...values.grades, { grade: "", classes: [""] }]);
  };

  const addClass = (gradeIndex) => {
    const newGrades = [...values.grades];
    newGrades[gradeIndex].classes.push("");
    setFieldValue("grades", newGrades);
  };

  return (
    <main>
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">Schule Struktur</h2>
      </div>

      <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px] h-[77vh] overflow-y-auto">
        <form onSubmit={handleSubmit} className="form mt-4 flex flex-col gap-4">
          <div className="space-y-1 w-full">
            {values.grades.map((grade, gradeIndex) => (
              <div key={gradeIndex} className="space-y-2 w-full">
                <div className="input-holder rounded-[6px]  dark:bg-gray-600 overflow-hidden bg-gray-100 flex justify-between items-center">
                  <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
                    Grad {gradeIndex + 1}
                  </p>
                  <input
                    type="text"
                    name={`grades[${gradeIndex}].grade`}
                    value={grade.grade}
                    onChange={handleChange}
                    placeholder="geben sie die note ein..."
                    className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
                  />
                </div>
                {errors.grades?.[gradeIndex]?.grade &&
                  touched.grades?.[gradeIndex]?.grade && (
                    <p className="text-red-700 text-xs">
                      {errors.grades[gradeIndex].grade}
                    </p>
                  )}
                {grade.classes.map((classItem, classIndex) => (
                  <div
                    key={classIndex}
                    className="input-holder rounded-[6px]  dark:bg-gray-600 overflow-hidden bg-gray-100 flex justify-between items-center"
                  >
                    <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
                      Klasse
                      {classIndex + 1}
                    </p>
                    <input
                      type="text"
                      name={`grades[${gradeIndex}].classes[${classIndex}]`}
                      value={classItem}
                      onChange={handleChange}
                      placeholder="betrete die klasse..."
                      className="input outline-none border-none  dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 py-3 px-6 bg-gray-200 w-full rounded-[6px]"
                    />
                  </div>
                ))}
                <div className="flex items-end w-full justify-end">
                  <button
                    type="button"
                    onClick={() => addClass(gradeIndex)}
                    className="mt-2 p-1 bg-blue-500  dark:bg-gray-200 dark:hover:bg-gray-500 dark:hover:text-white dark:text-gray-700 hover:bg-blue-400 flex items-center text-white rounded"
                  >
                    <IoIosAdd className="text-xl " /> Klasse
                  </button>
                </div>
              </div>
            ))}

            <div className="flex items-end w-full justify-end">
              <button
                type="button"
                onClick={addGrade}
                className="p-2 flex items-center border rounded-md hover:bg-gray-200 dark:hover:text-gray-700 mt-3 dark:text-white"
              >
                <IoIosAdd className="text-xl" /> Grad
              </button>
            </div>
          </div>

          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100  dark:bg-gray-600 flex justify-between items-center mt-2">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              E-Mail-Suffix
            </p>
            <input
              type="text"
              name="emailSuffix"
              value={values.emailSuffix}
              onChange={handleChange}
              placeholder="geben sie das e-mail-suffix ein..."
              className="input outline-none border-none py-3 px-6 bg-gray-200 w-full dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 rounded-[6px]"
            />
          </div>
          {errors.emailSuffix && touched.emailSuffix && (
            <div className="w-full flex items-end justify-end">
              <p className="text-red-700 text-xs">{errors.emailSuffix}</p>
            </div>
          )}

          <div className="flex items-end justify-end w-full gap-4 mt-6">
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300"
            >
              Stornieren
            </button>
            <button
              type="submit"
              className="bg-indigo-600 text-white py-2 px-7 h-[40px] w-[120px] flex items-center justify-center rounded-md hover:bg-indigo-700 transition duration-300"
            >
              {loading ? <ButtonLoader2 /> : "Einreichen"}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default SchoolStructure;
