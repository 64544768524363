import React, { useState, useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Select } from "antd"; // Import the Select component from antd
import useEditTicket from "../../hooks/useEditTicket";
import { ButtonLoader2 } from "../buttonLoader/ButtonLoader";
import Swal from "sweetalert2";

const TicketEditModel = ({ setShowEditModel, ticketDetail, getTickets }) => {
  const [subject, setSubject] = useState(ticketDetail?.title);
  const [status, setStatus] = useState(ticketDetail?.status);
  const [priority, setPriority] = useState(ticketDetail?.priority);
  const { EditingTicket, loading } = useEditTicket();

  useEffect(() => {
    setSubject(ticketDetail?.title);
    setStatus(ticketDetail?.status);
    setPriority(ticketDetail?.priority);
  }, [ticketDetail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {};
    if (subject !== ticketDetail.title) payload.title = subject;
    if (status !== ticketDetail.status) payload.status = status;
    if (priority !== ticketDetail.priority) payload.priority = priority;
    payload.id = ticketDetail.id;
    if (Object.keys(payload).length === 0) {
      Swal.fire({
        icon: "info",
        text: "No changes detected",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
      return;
    }

    const token = sessionStorage.getItem("token");

    try {
      await EditingTicket(payload, token);
      getTickets();
      setShowEditModel(false);
    } catch (error) {
      console.error("Error updating ticket:", error);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowEditModel(false);
  };

  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white relative dark:bg-gray-800 dark:text-white ">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-800 rounded-full cursor-pointer"
          onClick={() => setShowEditModel(false)}
        />
        <h2 className="text-xl font-bold py-4">Ticket Details</h2>
        <form className="form mt-4 flex flex-col gap-2" onSubmit={handleSubmit}>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-500  flex justify-between items-center">
            <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
              Subject
            </p>
            <input
              type="text"
              className="input outline-none border-none p-3 bg-gray-200 w-full dark:bg-gray-600  rounded-[6px] text-[12px] lg:text-[16px]"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100  dark:bg-gray-500 flex justify-between items-center">
            <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
              Status
            </p>
            <input
              type="text"
              className="input outline-none border-none p-3 bg-gray-200 w-full dark:bg-gray-600 rounded-[6px] text-[12px] lg:text-[16px]"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-500  flex justify-between items-center">
            <p className="py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
              Priority
            </p>
            <select
              value={priority}
              onChange={(value) => setPriority(value)}
              className="block h-[45px] w-full text-sm text-gray-900 bg-transparent rounded-md bg-gray-200 dark:bg-gray-600  px-4 dark:text-white appearance-none"
            >
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>

          <div className="flex items-end justify-end w-full gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="mt-4 bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 text-[12px] lg:text-[16px] h-[40px] w-[90px] flex items-center justify-center"
            >
              {loading ? <ButtonLoader2 /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TicketEditModel;
