import React, { useState, useEffect, useContext } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const data = [
  {
    name: "Jan 23",
    Schule: 33,
    Klassen: 22,
    Registrierte: 10,
  },
  {
    name: "Feb 23",
    Schule: 15,
    Klassen: 35,
    Registrierte: 30,
  },
  {
    name: "Mar 23",
    Schule: 21,
    Klassen: 30,
    Registrierte: 28,
  },
  {
    name: "Apr 23",
    Schule: 28,
    Klassen: 25,
    Registrierte: 23,
  },
  {
    name: "May 23",
    Schule: 20,
    Klassen: 55,
    Registrierte: 36,
  },
  {
    name: "Jun 23",
    Schule: 25,
    Klassen: 23,
    Registrierte: 44,
  },
  {
    name: "Jul 23",
    Schule: 30,
    Klassen: 20,
    Registrierte: 48,
  },
  {
    name: "Aug 23",
    Schule: 38,
    Klassen: 28,
    Registrierte: 15,
  },
  {
    name: "Sep 23",
    Schule: 40,
    Klassen: 25,
    Registrierte: 34,
  },
  {
    name: "Oct 23",
    Schule: 15,
    Klassen: 18,
    Registrierte: 21,
  },
  {
    name: "Nov 23",
    Schule: 32,
    Klassen: 36,
    Registrierte: 44,
  },
  {
    name: "Dec 23",
    Schule: 3,
    Klassen: 42,
    Registrierte: 25,
  },
];

const SupportBarChart = () => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer className="chart-container" width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={dark ? "#fff" : "#000"} />
        <XAxis dataKey="name" stroke={dark ? "#fff" : "#000"} />
        <YAxis stroke={dark ? "#fff" : "#000"} />
        <Tooltip />
        <Legend
          className="text-left"
          wrapperStyle={{ top: -40, left: 0, textAlign: "left" }}
        />
        <Bar
          dataKey="Schule"
          barSize={5}
          fill="#74fa86"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          dataKey="Klassen"
          barSize={5}
          fill="#2f53f5"
          activeBar={<Rectangle fill="gold" stroke="purple" />}
        />
        <Bar
          dataKey="Registrierte"
          barSize={5}
          fill="#FF0000"
          activeBar={<Rectangle fill="red" stroke="red" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SupportBarChart;
