import React, { PureComponent, useContext } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const data = [
  {
    name: "Jan 23",
    eingetragen: 33,
  },
  {
    name: "Feb 23",
    eingetragen: 15,
  },
  {
    name: "Mar 23",
    eingetragen: 21,
  },
  {
    name: "Apr 23",
    eingetragen: 28,
  },
  {
    name: "May 23",
    eingetragen: 20,
  },
  {
    name: "Jun 23",
    eingetragen: 25,
  },
  {
    name: "Jul 23",
    eingetragen: 30,
  },
  {
    name: "Aug 23",
    eingetragen: 38,
  },
  {
    name: "Sep 23",
    eingetragen: 40,
  },
  {
    name: "Oct 23",
    eingetragen: 15,
  },
  {
    name: "Nov 23",
    eingetragen: 32,
  },
  {
    name: "Dec 23",
    eingetragen: 3,
  },
];

const ClassBarChart = () => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer className="chart-container" width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={dark ? "#fff" : "#000"} />
        <XAxis dataKey="name" stroke={dark ? "#fff" : "#000"} />
        <YAxis postfix="k" stroke={dark ? "#fff" : "#000"} />
        <Tooltip stroke={dark ? "#fff" : "#000"} />
        <Legend
          className="text-left"
          wrapperStyle={{ top: -40, left: 0, textAlign: "left" }}
        />
        <Bar
          dataKey="eingetragen"
          barSize={5}
          fill="#2f53f5"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default ClassBarChart;
