import React, { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import useEditQuestion from "../../hooks/useEditQuestion";
import { ButtonLoader2 } from "../buttonLoader/ButtonLoader";
import Swal from "sweetalert2";

const QuestionEditModel = ({
  setShowEditModel,
  questionDetail,
  fetchQuestions,
}) => {
  const [question, setQuestion] = useState(questionDetail.question);
  const [answer, setAnswer] = useState(questionDetail.answer);
  const { EditingQuestion, loading } = useEditQuestion();

  const handleCancel = (e) => {
    e.preventDefault();
    setShowEditModel(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {};
    if (question !== questionDetail.question) payload.question = question;
    if (answer !== questionDetail.answer) payload.answer = answer;
    payload.id = questionDetail.id;
    if (Object.keys(payload).length === 0) {
      Swal.fire({
        icon: "info",
        text: "No changes detected",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
      return;
    }

    const token = sessionStorage.getItem("token");

    try {
      await EditingQuestion(payload, token);
      fetchQuestions();
      setShowEditModel(false);
    } catch (error) {
      console.error("Error updating ticket:", error);
    }
  };

  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white dark:bg-gray-700 dark:text-white relative">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:text-gray-800 rounded-full cursor-pointer"
          onClick={() => setShowEditModel(false)}
        />
        <h2 className="text-xl font-bold py-4">Frage Details</h2>
        <form className="form mt-4 flex flex-col gap-2" onSubmit={handleSubmit}>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3">Frage</p>
            <input
              type="text"
              className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3">Antwort</p>
            <input
              type="text"
              className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>

          <div className="flex items-end justify-end w-full gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="mt-4 bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
            >
              Stornieren
            </button>
            <button
              type="submit"
              className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 text-[12px] lg:text-[16px] h-[40px] w-[120px] flex items-center justify-center"
            >
              {loading ? <ButtonLoader2 /> : "Einreichen"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuestionEditModel;
